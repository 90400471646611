<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">Trainers</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'trainer-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['trainer-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add Trainer
                </button>
              </router-link>
            </div>

            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-show="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="trainer-datatable"
                v-show="!loading"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Employee ID</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th v-if="hasPermissions(['trainer-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody class="tableBody">
                  <tr v-for="(trainer, index) in trainers" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ trainer.user.name }}</td>
                    <td>{{ trainer.user.employee_id }}</td>
                    <td>
                      {{ trainer.user.email != 0 ? trainer.user.email : "-" }}
                    </td>
                    <td>
                      <badgeSuccess
                        v-if="trainer.status == 1"
                        :name="'Active'"
                      />
                      <badgeDanger v-else :name="'Inactive'" />
                    </td>
                    <td v-if="hasPermissions(['trainer-edit'])">
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'trainer-update',
                            params: { id: trainer.id },
                          }"
                        >
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </span>
                      <!-- <span @click="deleteTrainer(trainer.user.id)">
                        <i class="fas fa-trash-alt mx-1"></i>
                      </span> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      trainers: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllTrainers() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainers = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
        });
      $("#trainer-datatable").DataTable();
    },
    // async deleteTrainer(id) {
    //   axios
    //     .delete(`${this.baseUrl}admin/v1/trainers/` + id, {})
    //     .then(() => {
    //       this.toast.success("Successfully Trainer Deleted!");
    //       this.getAllTrainers();
    //     })
    //     .catch((error) => {
    //       this.errors = error.response.data.errors;
    //       this.toast.error("Something Went Wrong!");
    //     });
    // },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#trainer-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllTrainers();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
.table-responsive {
  max-height: 60vh;
  overflow-y: auto;
}
thead tr {
  position: sticky;
  background-color: white;
  top: 0;
}
</style>
